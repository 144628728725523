.handee-section {
  background: #c4ebfa;
}

.handee-container {
  align-items: stretch;
}

.handee-info {
  padding-top: 8.5rem;
  padding-bottom: 11.625rem;
  padding-left: 18rem;

}

.handee-info h2 {
  font-size: 5rem;
  font-weight: 700;
  line-height: 5.625rem;

}

.handee-info ul {
  margin-bottom: 2.5rem;
}

.handee-img {
  text-align: right;
  padding-top: 9rem;
}

.handee-price {
  font-size: 3.125rem;
  font-weight: 700;
  line-height: 3.375rem;
}

@media screen and (max-width: 1400px) {
  .handee-info {
    padding-left: 2rem;
    padding-right: 1rem;
  }

  .handee-img {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

}

@media screen and (max-width: 991px) {
  .handee-info {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 1rem;
  }

  .handee-img {
    padding-top: 1rem;
  }

  .handee-info h2 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 4.625rem;

  }

}
.iot-section {
  background: #f9f9f9;
}

.iot-container {
  align-items: stretch;
}

.iot-info {
  padding-top: 13.875rem;
  padding-bottom: 13.875rem;
  padding-left: 6rem;
  padding-right: 8rem;
}

.iot-info h2 {
  font-size: 5rem;
  font-weight: 700;
  line-height: 5.625rem;
  font-family: "D-DIN",
    sans-serif;

}

.iot-img {
  text-align: left;
  padding-top: 9rem;
}

@media screen and (max-width: 1399px) {
  .iot-info {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

@media screen and (max-width: 1199px) {
  .iot-info {
    padding-left: 2rem;
    padding-right: 1rem;
  }

  .iot-img {
    padding-top: 6rem;
    text-align: center;
  }
}

/* Mobile */
@media screen and (max-width: 991px) {
  .iot-info {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 1rem;
  }

  .iot-info h2 {
    font-size: 4rem;
    line-height: 4.625rem;
  }
}
@font-face {
  font-family: "DIN";
  src: url("./fonts/DIN-BoldAlternate.eot");
  src: url("./fonts/DIN-BoldAlternate.eot?#iefix") format("embedded-opentype"),
    url("./fonts/DIN-BoldAlternate.woff2") format("woff2"),
    url("./fonts/DIN-BoldAlternate.woff") format("woff"),
    url("./fonts/DIN-BoldAlternate.ttf") format("truetype"),
    url("./fonts/DIN-BoldAlternate.svg#DIN-BoldAlternate") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "D-DIN";
  src: url("./fonts/D-DIN-Bold.eot");
  src: url("./fonts/D-DIN-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/D-DIN-Bold.woff2") format("woff2"),
    url("./fonts/D-DIN-Bold.woff") format("woff"),
    url("./fonts/D-DIN-Bold.ttf") format("truetype"),
    url("./fonts/D-DIN-Bold.svg#D-DIN-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "D-DIN";
  src: url("./fonts/D-DIN.eot");
  src: url("./fonts/D-DIN.eot?#iefix") format("embedded-opentype"),
    url("./fonts/D-DIN.woff2") format("woff2"),
    url("./fonts/D-DIN.woff") format("woff"),
    url("./fonts/D-DIN.ttf") format("truetype"),
    url("./fonts/D-DIN.svg#D-DIN") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

html,
body {
  font-family: "D-DIN",
    sans-serif;
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
  text-rendering: optimizeLegibility;
  color: #000000;
}

body {
  overflow-x: hidden;
  min-height: 100vh;
  font-size: 16px;
}

img {
  -webkit-backface-visibility: hidden;
  -ms-transform: translateZ(0);
  /* IE 9 */
  -webkit-transform: translateZ(0);
  /* Chrome, Safari, Opera */
  transform: translateZ(0);
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

::-moz-selection {
  /* Code for Firefox */
  color: #ffffff;
  background: #000000;
}

::selection {
  color: #ffffff;
  background: #000000;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.btn {
  transition: all .2s ease-in-out;
  padding: 1.375rem 2.75rem;
  border-radius: 40px;
  font-weight: 700;
}

.btn-primary {
  background: #f20044;
  color: #fff;
  border-color: #f20044;
  font-family: "D-DIN",
    sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
  letter-spacing: 2.25px;
}

.btn-primary:hover,
.btn-primary:focus {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}

.container {
  max-width: 1350px;
}

.slick-dots {
  bottom: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%
    /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}


.player-wrapper-full {

  height: calc(100vh - 125px);

}

.react-player-full {
  width: 100% !important;
  background-color: #CFCAC7;
  height: calc(100vh - 125px) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-4 {
  border-radius: 0.5rem !important;
}
.mainNavbar.bg-opacity {
  background: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  position: fixed;
}

.mainNavbar {
  position: relative;
  top: 0px;
  width: 100%;
  z-index: 1030;
  transition: all 0.2s ease-in-out;
  padding-top: 1.875rem;
  padding-bottom: 0.625rem;
}

.main_menu {
  align-items: center;
}

.nav_items {
  margin: 0 1.25rem;
}

.navbar-expand-lg .navbar-nav .nav_items .nav-link {
  position: relative;
  padding: 1.25rem 0;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #000;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.navbar-expand-lg .navbar-nav .nav_items .nav-link::-moz-selection {
  /* Code for Firefox */
  color: #f20044;
  background: transparent;
}

.navbar-expand-lg .navbar-nav .nav_items .nav-link::selection {
  color: #f20044;
  background: transparent;
}

.navbar-expand-lg .navbar-nav .nav_items .nav-link:hover,
.navbar-expand-lg .navbar-nav .nav_items .nav-link:focus {
  color: #f20044 !important;
}

.navbar-expand-lg .navbar-nav .nav_items .nav-link.active {
  color: #f20044 !important;
}

.contact_btn.btn {
  background: #f20044;
  font-size: 1.25rem;
  letter-spacing: normal;
  color: white;
  padding: 1rem 2.75rem;
  margin-left: 5rem;
  border-color: #f20044;
  font-family: "D-DIN",
    sans-serif;
  line-height: 1.5;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  border-radius: 40px;
  transition: all .2s ease-in-out;
}

.contact_btn.btn:hover,
.contact_btn.btn:focus {
  color: white;
  background-color: #000;
  border-color: #000000;
}

/* Tablet */
@media screen and (max-width: 1200px) {
  .contact_btn.btn {
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

/* Mobile */
@media screen and (max-width: 991px) {
  .main_menu {
    padding: 20px 0;
  }

  .contact_btn {
    width: 50px;
    height: 55px;
    margin: 15px 0 0;
  }

  .mainNavbar {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .nav_items {
    margin: 0;
  }

  .navbar-expand-lg .navbar-nav .nav_items .nav-link {
    padding: 0.75rem 0;
    display: block;
    width: 100%;
  }
}

/* i-pad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .nav_items {
    padding: 5px;
    font-size: 18px;
    margin-top: 7px;
  }

  .group_logo_img {
    max-height: 75px;
  }

  .contact_btn {
    height: 50px;
    margin-top: 9px;
  }
}
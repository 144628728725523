.hero-section {
  background-color: #f9f9f9;
  padding: 5% 0;
}

.hero-container {
  align-items: stretch;
}


.hero-img {
  text-align: center;
  background: transparent;
}

.slick-next,
.slick-prev {
  width: 25px;
  height: 23px;
  z-index: 1;
}

.slick-dots,
.slick-next,
.slick-prev {
  padding: 3px;
}

.sanitiser_img .slick-prev {
  left: 3rem;
}

.sanitiser_img .slick-next {
  right: 3rem;
}


.slick-slide {
  padding: 0 2rem;


}

.slick-slide img {
  border-radius: 0.5rem;
}



/* Mobile */
@media screen and (max-width: 991px) {

  .Handee_main {
    overflow: hidden;
  }

}
.Handee_main {
  background-color: #f9f9f9;
  /* position: relative; */
}

.hero-section {
  background-color: #f9f9f9;
}

.hero-container {
  align-items: stretch;
}

.hero-info {
  background-color: #f9f9f9;
  padding-top: 8rem;
  padding-bottom: 8rem;
  /* padding-left: 18rem;
  padding-right: 8rem; */
  height: 100%;
}

.hero-info-container {
  max-width: 480px;
}

.hero-info h1 {
  color: rgba(0, 0, 0, .84);
  font-size: 5rem;
  font-weight: 700;
  line-height: 5.625rem;
}

.hero-info p {
  margin-bottom: 1.5rem;
}

.hero-img {
  text-align: center;
  background: transparent;
}




/* Mobile */
@media screen and (max-width: 1400px) {

  .hero-info {
    padding-left: 1rem;
    padding-right: 1rem;
  }

}

/* Mobile */
@media screen and (max-width: 991px) {
  .hero-info {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .handee_xs_text {
    padding: 30px 0;
    margin: 0 auto;
  }

  .Handee_smart_text {
    margin: 0;
    text-align: center;
    font-size: 40px;
  }

  .Handee_disc_text {
    margin: 5% 0%;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }

  .btn-handee .Touchless_order_now_btn {
    margin: 8px 0 0 0;
    min-width: 150px;
    height: 50px;
    line-height: 36px;
  }

  .btn-handee {
    text-align: center;
  }

  .hand_litre {
    opacity: 0.4;
  }

  .Handee_main {
    overflow: hidden;
  }

  .hero-info h1 {

    font-size: 4rem;

    line-height: 4.625rem;
  }

  /* .sanitiser_img {
    background-size: contain;
    position: initial;
    height: 400px;
    width: 100%;
  } */
}

/* i-pad  */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .handee_xs_text {
    padding: 40px 0;
  }

  .Touchless_order_now_btn {
    margin: 0;
  }
}

/* i-pad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {

  /* stylelint-disable-line */
  .Handee_smart_text {
    font-size: 65px;
  }

  .Handee_disc_text {
    font-size: 18px;
  }
}
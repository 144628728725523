.main_trust {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.trusted_by {
  margin: 0;
  font-family: "D-DIN", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.875rem;
}

.client-slide-section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.main_trust img {
  max-width: 100%;
  margin: auto;
}

.main_trust .slick-track {
  display: flex;
  align-items: center;
}

.main_trust .slick-prev {
  left: 0;
}

.main_trust .slick-next {
  right: 0;
}

/* Mobile */
@media screen and (max-width: 991px) {
  .main_trust {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .main_trust .slick-next {
    right: 0;
  }

  .main_trust .slick-dots {
    bottom: -10px;
  }

  .main_trust {
    padding: 30px 0;
  }

  .trusted_by {
    margin-left: 37%;
  }
}

/* i-pad  */
@media screen and (min-width: 768px) and (max-width: 991px) {

  /* stylelint-disable-line */
  .main_trust {
    padding: 40px 0;
  }
}
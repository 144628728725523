.Touchless_Machine_Main {
  background-color: #e1f5fc;
  padding: 5% 0%;
}

.Touchless_machine_text {
  margin: 0 0 25px 0;
  font-size: 80px;
  font-family: "D-DIN", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.84);
}

.Touchless_machine_dis_1 {
  margin: 12px 100px 25px 0;
  font-family: "D-DIN", sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
}

.Touchless_machine_dis_2 {
  margin: 12px 100px 0 0;
  font-family: "D-DIN", sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
}

.Handee_machine_text {
  font-family: "D-DIN", sans-serif;
  font-size: 45px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.Handee_machine_ul {
  margin: 30px 0 0 0;
  font-family: "D-DIN", sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #000;
}

.order_now_btn.btn {
  height: 60px;
  min-width: 200px;
  margin-top: 20px;
  border-radius: 40px;
  background-color: #f20044;
  border: none;
  line-height: 43px;
}

/* Mobile */
@media screen and (max-width: 991px) {
  .Touchless_Machine_Main {
    padding: 30px 0;
  }

  .Touchless_machine_text {
    text-align: center;
    font-size: 40px;
  }

  .Touchless_machine_dis_1 {
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .Touchless_machine_dis_2 {
    width: 100%;
    padding-bottom: 10%;
    text-align: center;
    font-size: 16px;
  }

  .Handee_machine_text {
    padding-top: 40px;
    text-align: center;
    font-size: 40px;
  }

  .Handee_machine_ul {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  .Handee_machine_Main {
    width: fit-content;
    display: flex;
    margin: auto;
    flex-direction: column;
    max-width: max-content;
  }

  .Handee_machine_Main .order_now_btn {
    margin: 20px 0 0 25px;
    min-width: 150px;
    height: 50px;
    line-height: 36px;
  }

  .Handee_machine_Main .btn-primary {
    max-width: fit-content;
    margin: auto;
  }
}

/* i-pad  */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .Touchless_machine_text {
    margin-bottom: 0;
  }

  .Touchless_machine_dis_1 {
    margin-bottom: 0;
  }

  .Touchless_machine_dis_2 {
    padding-bottom: 50px;
  }

  .Handee_machine_text {
    padding-top: 40px;
  }

  .Touchless_Machine_Main {
    padding: 40px 0;
  }

  .order_now_btn {
    margin: 25px 85px 0;
  }
}

/* i-pad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {

  /* stylelint-disable-line */
  .Touchless_machine_text {
    font-size: 60px;
  }

  .Touchless_machine_dis_1 {
    font-size: 18px;
  }

  .Touchless_machine_dis_2 {
    font-size: 18px;
  }

  .Handee_machine_text {
    font-size: 40px;
  }

  .Handee_machine_ul {
    font-size: 18px;
  }
}
.footer {
  background-color: #f9f9f9;
}

.links {
  font-family: "D-DIN", sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #000;
  text-decoration: none;
}

/* Mobile */
@media screen and (max-width: 991px) {/* stylelint-disable-line */
  .footer {
    text-align: center;
  }
}

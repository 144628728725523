.form {
  padding: 5% 0%;
}

.form_inner {
  background-color: #f9f9f9;
  border-radius: 50px;
  padding: 40px;
  margin-bottom: 70px;
}

.form_inner .btn {
  padding: 0.875rem 2rem;
}

.we_have_text {
  font-family: "D-DIN", sans-serif;
  font-size: 80px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.we_have_dis {
  font-family: "D-DIN", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  padding: 15px 0 50px 0;
}

.you_can_text {
  font-family: "D-DIN", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  padding: 20px 0 0px 0;
}

.i_want_text_to {
  font-size: 18px;
}

.para_text_size {
  font-size: 18px;
  margin-bottom: 0;
  line-height: normal;
}

.main_text {
  max-width: 520px;
}

.main_form {
  /* max-width: 520px; */
}

.Or_text {
  font-weight: 600;
}

.i_would_like_text {
  font-weight: 600;
}

.i_would_like_inner_text {
  font-weight: normal;
  margin-bottom: 0;
}

.i_want_text {
  font-weight: 600;
}

.i_want_inner_text {
  font-weight: normal;
  margin-bottom: 0;
}

.how_many_text {
  font-weight: 600;
  line-height: normal;
}

.how_many_inner_text {
  font-weight: normal;
  margin-bottom: 0;
}

.main_delivery_text {
  margin: 10px 0 0 0;
}

.form_text {
  font-weight: 600;
}

.litres_5_dropdown {
  max-width: 100px;
  width: 100%;
  height: 40px;
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  font-weight: bold;
}

/* .enquire_btn.btn {
  background-color: #f20044;
  border: none;
  padding: 15px 25px 15px 25px;
  border-radius: 40px;
}

.enquire_btn.btn:hover,
.enquire_btn.btn:active,
.enquire_btn.btn:focus {
  background-color: #f20044;
  box-shadow: none;
} */

textarea {
  resize: none;
}

.from_control_lable:not(textarea) {
  height: 50px;
}

.custm_checkbox .form-check-input {
  height: 24px;
  width: 24px;
}

.custm_checkbox .form-check-input:checked[type="checkbox"] {
  background-image: url("../../assets/checkbox.svg");
  background-color: white;
  background-size: 16px 14px;
}

.custm_checkbox .form-check-input:focus {
  border-color: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.price_item {
  background-color: #c4ebfa;
  border-radius: 35px;
  padding: 30px 40px;
}

.your_price_text {
  font-family: "D-DIN", sans-serif;
  font-size: 3.25rem;
  font-weight: 600;
  color: #000;
  margin-bottom: 0;
  margin-top: 38px;
}

.machine_rental_text {
  font-family: "D-DIN", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
  display: block;
}

.machine_rental_inner_text {
  font-weight: normal;
  font-size: 18px;
}

.branded_text {
  font-family: "D-DIN", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

.branded_inner_text {
  font-weight: normal;
  font-size: 18px;
}

.handee_sanitiser_text {
  font-family: "D-DIN", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

.handee_sanitiser_inner_text {
  font-weight: normal;
  font-size: 18px;
}

.delivery_text {
  font-family: "D-DIN", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

.delivery_inner_text {
  font-weight: normal;
  font-size: 18px;
}

.reachargable_text {
  font-family: "D-DIN", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

.reachargable_inner_text {
  font-weight: normal;
  font-size: 18px;
}

.terms_text {
  font-family: "D-DIN", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

.Only_Machine_text {
  font-family: "D-DIN", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}

.Only_Machine_innner_text {
  font-weight: normal;
  font-size: 18px;
}

.terms_inner_text {
  font-weight: normal;
  font-size: 18px;
}

.yes_text {
  font-weight: bold;
}

.Cost_text {
  font-weight: bold;
  font-size: 18px;
}

.price_text {
  font-weight: bold;
}

.r800_once_text {
  font-family: "D-DIN", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  color: #000;
}

.delivery_deposit_text {
  font-weight: normal;
  font-size: 25px;
  padding-left: 5px;
}

.r325_per_month_text {
  font-size: 50px;
  font-weight: 600;
  color: #000;
  line-height: normal;
}

.all_prices_exl_text {
  font-size: 18px;
  font-weight: 300;
  line-height: 2;
}

.error {
  color: #f20044;
  font-size: 17px;
}

.custm_checkbox .form-check-input[type="radio"] {
  border-radius: 0.25em;
  margin-top: 0;
}

.custm_checkbox .form-check-input:checked[type="radio"] {
  background-image: url(../../assets/checkbox.svg);
  background-color: white;
  background-size: 16px 14px;
}

.leftside-sec-error-msg {
  color: #f20044;
  margin-top: -15px;
}

.leftside-sec-error-msg-dropdown {
  color: #f20044;
  margin-top: 10px;
}

/* Mobile */
@media screen and (max-width: 991px) {
  .form {
    padding: 5% 0%;
  }

  .we_have_text {
    font-size: 40px;
  }

  .we_have_dis {
    padding: 8% 0;
    font-size: 17px;
  }

  .form_inner {
    padding: 0 0 15px;
    border-radius: 25px;
    margin: 0 -15px;
  }

  .main_delivery_text {
    margin-top: 30px;
  }

  .you_can_text {
    text-align: center;
    padding: 15px;
    font-size: 18px;
  }

  .para_text_size {
    font-size: 17px;
  }

  .main_text {
    margin: 5% 5%;
    max-width: 800px;
  }

  .main_form {
    padding: 0;
    max-width: 800px;
  }

  .enquire_btn {
    max-width: 800px;
  }

  .enquire_btn_mobile_view {
    max-width: 800px;
    margin-top: 50px;
  }

  .price_item {
    border-radius: 15px;
    padding: 15px 20px 10px;
  }

  .price_item_main {
    width: calc(100% - 30px);
    margin: 15px auto 0;
  }

  .your_price_text {
    text-align: center;
    font-size: 35px;
  }

  .machine_rental_text {
    font-size: 18px;
    line-height: normal;
    margin-bottom: 5px;
  }

  .machine_rental_inner_text {
    font-size: 15px;
  }

  .Only_Machine_text {
    font-size: 18px;
  }

  .Only_Machine_innner_text {
    font-size: 15px;
  }

  .Cost_text {
    font-size: 15px;
  }

  .branded_text {
    font-size: 18px;
  }

  .branded_inner_text {
    font-size: 15px;
  }

  .handee_sanitiser_text {
    font-size: 18px;
  }

  .handee_sanitiser_inner_text {
    font-size: 15px;
  }

  .delivery_text {
    font-size: 18px;
  }

  .delivery_inner_text {
    font-size: 15px;
  }

  .reachargable_text {
    font-size: 18px;
  }

  .reachargable_inner_text {
    font-size: 15px;
  }

  .terms_text {
    font-size: 18px;
  }

  .terms_inner_text {
    font-size: 15px;
  }

  .delivery_deposit_text {
    font-size: 15px;
  }

  .r325_per_month_text {
    font-size: 18px;
  }

  .all_prices_exl_text {
    font-size: 14px;
  }

  .r800_once_text {
    font-size: 18px;
  }
}

/* i-pad  */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .form {
    padding: 40px 0;
  }

  .we_have_dis {
    padding: 20px 0;
  }

  .you_can_text {
    padding: 0 0;
  }

  .main_text {
    margin: 5% 10%;
  }

  .main_form {
    margin: 5% 5%;
  }

  .price_item {
    padding: 40px 65px 45px;
    margin-top: 20px;
  }
}

/* i-pad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {

  /* stylelint-disable-line */
  .we_have_text {
    font-size: 60px;
  }

  .we_have_dis {
    font-size: 23px;
  }

  .form_inner {
    padding: 25px;
  }

  .you_can_text {
    font-size: 18px;
  }

  .para_text_size {
    font-size: 22px;
  }

  .price_item {
    padding: 50px 40px 60px;
  }

  .your_price_text {
    font-size: 40px;
  }

  .machine_rental_text {
    font-size: 22px;
  }

  .branded_text {
    font-size: 22px;
  }

  .delivery_text {
    font-size: 22px;
  }

  .reachargable_text {
    font-size: 22px;
  }

  .terms_text {
    font-size: 22px;
  }

  .r800_once_text {
    font-size: 24px;
  }

  .delivery_deposit_text {
    font-size: 17px;
  }

  .r325_per_month_text {
    font-size: 1.5rem;
  }

  .all_prices_exl_text {
    font-size: 17px;
  }

  /* stylelint-disable-next-line declaration-no-important */
}
.brand_all_images {
  padding: 5% 0% 8%;
}

.branded_options_text {
  margin: 0 0 15px 0;
  font-size: 60px;
  font-family: "D-DIN", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.branded_options_dis {
  margin: 20px 0 60px 0;
  font-size: 20px;
  font-family: "D-DIN", sans-serif;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
}

.all_img {
  margin: 0 40px;
  margin-bottom: 60px;
}

/* .all_img .slick-dots {
  bottom: -50px;
} */

/* Mobile */
@media screen and (max-width: 991px) {
  .brand_all_images {
    padding: 30px 0 0 0;
  }

  .branded_options_text {
    text-align: center;
    font-size: 40px;
  }

  .branded_options_dis {
    margin-bottom: 30px;
    text-align: center;
    font-size: 16px;
  }

  .all_img {
    margin: 50px 100px;
  }

  .all_img .slick-slide img {
    display: inherit;
  }

  .slick-next {
    right: 0;
  }

}

/* i-pad  */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .brand_all_images {
    padding: 40px 0 0 0;
  }

  .branded_options_dis {
    margin-bottom: 0;
  }
}

/* i-pad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {

  /* stylelint-disable-line */
  .branded_options_text {
    font-size: 50px;
  }

  .branded_options_dis {
    font-size: 18px;
  }
}